/* You can add global styles to this file, and also import other style files */
@import "~quill/dist/quill.snow.css";

html { height: 100vh; }
body { height: 100%; }
body{
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    margin: 0;
    height: 100%;
    overflow: hidden;
    max-height: 100vh;
}

:root {
    --primaryColor: #007991;
}
.base-color1{
    color: #007991;
}
.base-color2{
    color: #2E4A66;
}
.base-bg1{
    background: #007991;
}
.base-bg2{
    background: #2E4A66;
}
.btn-primary{
    background: #007991;
    border: #007991;
    &:hover {
        background:#2E4A66;
    }
}
h2{
    line-height: 23px;
    padding-right: 2%;
    font-family: Arial, Helvetica, sans-serif !important;
        font-size: 14px!important;
        font-weight: 450!important;
    span{
        font-family: Arial, Helvetica, sans-serif !important;
        font-size: 14px!important;
        font-weight: 450!important;
        .size-full , .SAS_Image {
            max-width: 500px;
            max-height: 500px;
            margin-bottom: 4px;
        }
    }

}
.aligncenter , .SAS_Image{
  transition: 1s all ease-in-out;
  &:hover{
    max-width: 400px;
    max-height: 400px;
    object-fit: contain;
    -ms-transform: scale(1.6) !important; /* IE 9 */
    -webkit-transform: scale(1.6) !important; /* Safari 3-8 */
    //transform: scale(2,2) !important;
    transform: scale(1.6) !important;
  }
}
// Images i Explanation of Quiz.
.size-full , .SAS_Image {
  max-width: 400px;
  max-height: 400px;
  display: block;
  margin: 14px auto;
  transition: 1s all ease-in-out;

}
.size-full:hover, .SAS_Image:hover {
  max-width: 400px;
  max-height: 400px;
  object-fit: contain;
  -ms-transform: scale(1.8) !important; /* IE 9 */
  -webkit-transform: scale(1.8) !important; /* Safari 3-8 */
  //transform: scale(2,2) !important;
  transform: scale(1.8) !important;
}

.sdm_download_link{
    display: flex;
    flex-direction: row;
    justify-content: space-around;
}
.sdm_download_count{
    display: flex;
    flex-direction: row;
    justify-content: center;
    .sdm_count_number{
        margin-right: 4px;
    }
}

.sdm_download{

    background-color: #007991;
    padding: 0.5rem 1rem;
    color: #FFFFFF;
    border-radius: 3px;
    &:hover{
        color: #dbd8d8;
        background-color: #029bb9;
    }
}
p{
    margin-bottom: 1px!important;
}
ul{
    margin-bottom: 1px;
}
#Video_Content_Area{
    img{
        max-width: 100%;
    }
    h5{
        display: flex;
        color: #FFFFFF;
        font-size: 16px;
        a{
            margin: 0 auto;
            border: 2px solid #029bb9 ;
            padding: 0.5rem 1rem;
            color: #029bb9;
            &:hover{
                color: #FFFFFF;
                background-color: #029bb9;
            }
            // &::before{
            //     content: "Download ";
            // }
        }
    }
    .panel-layout{
        .panel-grid{
            .panel-grid-cell{
                .widget{
                    .textwidget{
                        h3{
                            span{
                                color: #0e0d0dcc;
                            }
                        }
                    }
                }
            }
        }
    }
    span{
        font-size: 16px;
    }

}

// .p-datatable {
//     .p-datatable-header {
//    background-color: red !important;
// }
// }
.useractivity_table{
.p-datatable {
    .p-paginator {
//    background-color: yellow !important;

   .p-paginator-pages{ .p-paginator-page{
    color: #E34F6B !important;
    &:hover{
        background: #E34F6B !important;
        color: #FFFFFF !important;
    }
    .p-highlight {
        background: #0a5a94;
        border-color: #E3F2FD;
    }

   }

}
   .p-paginator-first{
    color: #E34F6B !important;
   }
   .p-paginator-prev{
    color: #E34F6B !important;
   }
   .p-paginator-next{
    color: #E34F6B !important;
   }
   .p-paginator-last{
    color: #E34F6B !important;
   }
}
}
}
.Dropdown_useractivity{
    .p-dropdown{
        border: #007991 solid 3px;
        &:hover{
            border: #024653 solid 3px;
        }
        .p-dropdown-label{
            padding: 1px;
            font-size: 14px;
    }
    .pi-chevron-down{
        color: #007991;
    }
    }
}
.Past{
    .p-dropdown{
        border: #007991 solid 3px;
        &:hover{
            border: #024653 solid 3px;
        }
        .p-dropdown-label{
            padding: 0px;
            font-size: 14px;
            padding-left: 4px;
            color: #343434;
            font-weight: 600;
    }
    .pi-chevron-down{
        color: #007991;
    }
    }
}
.dropdown_username{
    .p-dropdown{
        z-index: 100000;
        position: relative;
        max-width: 100%;
        border: #007991 solid 2px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-basis: 100%;
        &:hover{
            border: #024653 solid 2px;
        }
        .p-dropdown-label{
            flex-basis: 70%;
            padding: 0px;
            font-size: 14px;
            padding-left: 4px;
            color: #343434;
            max-width: 90%;
    }
    .pi-chevron-down{
        flex-basis: 10%;
        color: #007991;
        max-width: 10%;
    }
    }
}
.bankdetails_text{
    font-weight: 650px !important;
}


.dropdown-rightsidebar{
    .p-dropdown{
        z-index: 100000;
        position: relative;
        max-width: 100%;
        border: #007991 solid 2px;
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        flex-basis: 100%;
        border-radius: 8px;
        margin-bottom: 5vh;
        &:hover{
            border: #024653 solid 2px;
        }
        .p-dropdown-label{
            flex-basis: 70%;
            padding: 0px;
            font-size: 14px;
            padding-left: 4px;
            color: #343434;
            max-width: 90%;
            font-weight: 600;
    }
    .pi-chevron-down{
        flex-basis: 10%;
        color: #007991;
        max-width: 10%;
    }
    }
}
.textwidget{
    align-content: center;
    text-align: center;
}

// FLASHCARDS IMAGES
#Question_Flashcard{
    font-size: 16px;
}
#Answer_Flascard{
    font-size: 16px;
}
#Answer_Flascard , #Question_Flashcard{
    img{
        margin-left: auto;
        margin-right: auto;
        display: block;
        height: auto;
        margin-top: 2px;
        min-width: 500px !important;
        max-width: 500px !important;
        transition: 1s all ease-in-out;
        &:hover{
            max-width: 500px;
            max-height: 500px;
            object-fit: contain;
            -ms-transform: scale(1.6) !important; /* IE 9 */
            -webkit-transform: scale(1.6) !important; /* Safari 3-8 */
            //transform: scale(2,2) !important;
            transform: scale(1.6) !important;
          }
    }
}

.explanation-description{
    img{
        margin-left: auto;
        margin-right: auto;
        display: block;
        height: auto;
        margin-top: 2px;
        min-width: 500px !important;
        max-width: 500px !important;
        transition: 1s all ease-in-out;
        &:hover{
            max-width: 500px;
            max-height: 500px;
            object-fit: contain;
            -ms-transform: scale(1.6) !important; /* IE 9 */
            -webkit-transform: scale(1.6) !important; /* Safari 3-8 */
            //transform: scale(2,2) !important;
            transform: scale(1.6) !important;
          }
    }
    table{
        width: 100%;
        border: 1px solid black;
        tr{
            &:first-child{
                strong{
                    display: block;
                    margin: auto;
                    text-align: center;
                    font-weight: 700;
                }

              span{
                strong{
                  display: inline !important;}
              }
            }
        td{
            // width: 50%;
            border: 1px solid rgb(163, 163, 163);
            padding: 0.3rem 1rem;
            div{
                font-weight: 600;
            }
        }
        }
    }
}

.highlight{
    background-color: yellow;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
}

.search {
    background-color: yellow !important;
}

.csq-quiz-question-area{
    img{
        margin-left: auto;
        margin-right: auto;
        display: block;
        height: auto;
        margin-top: 2px;
        min-width: 500px !important;
        max-width: 500px !important;
        transition: 1s all ease-in-out;
        &:hover{
            max-width: 500px;
            max-height: 500px;
            object-fit: contain;
            -ms-transform: scale(1.6) !important; /* IE 9 */
            -webkit-transform: scale(1.6) !important; /* Safari 3-8 */
            //transform: scale(2,2) !important;
            transform: scale(1.6) !important;
          }
    }
    table{
        width: 100%;
        border: 1px solid black;
        tr{
            &:first-child{
                strong{
                    display: block;
                    text-align: center;
                    font-weight: 700;
                }
                span{
                  strong{
                    display: inline !important;}
                }
            }
        td{
            width: 50%;
            border: 1px solid rgb(163, 163, 163);
            padding: 0.3rem 1rem;
        }
        }
    }
}





:host ::ng-deep .p-treetable-scrollable-header-box{
    padding-right: 0px;
}

.p-hidden-accessible{ display: none; }

.explanation-description table {
    overflow-x: scroll;
    width: 95%;
}



@media screen and (max-width: 550px) {
    .size-full, .SAS_Image {
        max-width: 100%;
        max-height: 400px;
        display: block;
        margin: 14px auto;
        transition: 1s all ease-in-out;

      }
    .size-full, .SAS_Image:hover {

        object-fit: fill;
        -ms-transform: scale(1.2) !important; /* IE 9 */
        -webkit-transform: scale(1.2) !important; /* Safari 3-8 */
        //transform: scale(2,2) !important;
        transform: scale(1.2) !important;
        max-width: 88vw !important;
      }


      #explanation-description , #Answer_Flascard , #Question_Flashcard{
        img{
            margin-left: auto;
            margin-right: auto;
            display: block;
            height: auto;
            margin-top: 2px;
            min-width: 92% !important;
            max-width: 92% !important;
            transition: 1s all ease-in-out;
            &:hover{
                max-width: 80%;
                // max-height: 80%;
                object-fit: contain;
                -ms-transform: scale(1.3) !important; /* IE 9 */
                -webkit-transform: scale(1.3) !important; /* Safari 3-8 */
                //transform: scale(2,2) !important;
                transform: scale(1.3) !important;
              }
        }
    }
    .explanation-description table tr td {
        padding: 0.3rem 0.5rem;
    }
    .explanation-description{
        max-width: 95vw;
        img{
            margin-left: auto;
                margin-right: auto;
                display: block;
                height: auto;
                margin-top: 3rem;
                margin-bottom: 2rem;
                min-width: 92% !important;
                max-width: 92% !important;
                transition: 1s all ease-in-out;
                &:hover{
                    max-width: 80%;
                    // max-height: 80%;
                    object-fit: contain;
                    -ms-transform: scale(1.3) !important; /* IE 9 */
                    -webkit-transform: scale(1.3) !important; /* Safari 3-8 */
                    //transform: scale(2,2) !important;
                    transform: scale(1.3) !important;
                  }
        }
    }

    .csq-quiz-question-area{
        max-width: 95vw;
        img{
            margin-left: auto;
                margin-right: auto;
                display: block;
                height: auto;
                margin-top: 3rem;
                margin-bottom: 2rem;
                min-width: 92% !important;
                max-width: 92% !important;
                transition: 1s all ease-in-out;
                &:hover{
                    max-width: 80%;
                    // max-height: 80%;
                    object-fit: contain;
                    -ms-transform: scale(1.3) !important; /* IE 9 */
                    -webkit-transform: scale(1.3) !important; /* Safari 3-8 */
                    //transform: scale(2,2) !important;
                    transform: scale(1.3) !important;
                  }
        }
    }
}



video {
  pointer-events: none;
}

  video::-internal-media-controls-download-button {
    display:none;
   }

   video::-webkit-media-controls-enclosure {
        overflow:hidden;
   }

   video::-webkit-media-controls-panel {
        width: calc(100% + 30px);
   }

// AUDIO TAG STYLING
audio{
    margin-left: auto;
    margin-right: auto;
    display: block;
}


.csq-quiz-question-area{
    video{
        display: block;
        margin-left: auto;
        margin-right: auto;
    }
}


// Table
#table_div{
    width: 100%;
    table{
        width: 100%;
    }
}
.tablepress tfoot th, .tablepress thead th {
    background-color: #d9edf7;
    font-weight: 700;
    vertical-align: middle;
    &:hover{
        background-color: #049cdb;
    }
}

.table_Area{
    margin-top: 1rem;
    table {
        thead{
            tr{
                background-color: #00B7FF;
                th{
                    color: #FFFFFF;
                    background-color: #00B7FF;
                    &:hover{
                        background-color: #00a2ff;
                    }
                }
            }
        }
    }
}


label{
    margin-bottom: 0px !important;
    display: inline;
  }


.csq-modals-dialog-panels-wrapper{
    // padding-bottom: 20px;
	// margin-top: 9vh !important;
    // // min-height: 82vh !important;
    // max-height: 70vh !important;
	// margin-bottom: 7vh;
	// margin-top: 9vh;
    min-height: 90vh;
    max-height: 92vh;
}
.csq-top-nav-bar-wrapper{
    min-height: 8vh;
    max-height: 10vh;
}
.csq-main-body-area{
    min-height: 78vh;
    max-height: 78vh;
    padding-bottom: 5rem;
}
.csq-footer-wrapper{
    min-height: 8vh;
    max-height: 10vh;
}
@media only screen and (max-width: 1015px)   {
	.csq-modals-dialog-panels-wrapper{
		max-height: 78vh !important;
		// margin-bottom: 9vh;
		// margin-top: 13vh;
		// margin-bottom: 10vh;
		overflow: scroll;
		overflow-x: hidden;
		max-width: 99%;
        padding-bottom: 4rem;
	}
}
body, html{
  -webkit-touch-callout: none !important;
}
#launcher{
    display: none !important;
  margin-bottom: 3rem !important;
}
.label-6ayow{
  display: none !important;
&:after{
  content: 'Questions' !important;
}
}
.label-6AYOW{
  display: none !important;
  &::after{
    content: 'Questions';
  }
}


.flex {
    display: flex;
  }
  .flex-column {
    flex-direction: column;
  }
  .justify-between {
    justify-content: space-between;
  }
  .justify-around {
    justify-content: space-around;
  }
  .fit-content-height {
    height: fit-content !important;
  }
  .justify-center {
    justify-content: center;
  }
  .justify-end {
    justify-content: end;
  }
  .justify-start {
    justify-content: start !important;
  }
  .align-center {
    align-items: center;
  }
  .pointer{
    cursor: pointer;
  }

  .editor-headings{

    h2{
        font-size: 1.5rem !important;
    }
    h3{
        font-size: 1.3rem !important; 
    }
    h4{
        font-size: 1.1rem !important; 
    }
  }

  .article-content-wrapper {
    h1 {
    
        font-size:1.6rem;
        color:rgb(26, 28, 30);
    
  }
    h3{
        font-size: 20px;
        color: var( --primaryColor);
        font-weight: 300;
    }
    p , li{
        margin: 1rem 0rem;

    }
  }
  
  .disable{
    pointer-events: none;
    opacity: .5;
  }
  
  .highlighted {
    background-color: yellow;
   
  }

  mark{
    background-color: yellow;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
  }

  .highlighter-1 {
    background-color: #ff0;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
  }
  .highlighter-2 {
    background-color: #b2ff59;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
  }
  .highlighter-3 {
    background-color: #85feff;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
  }
  .highlighter-4 {
    background-color: #fcb1cb;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
  }
  .highlighter-5 {
    background-color: #ffac00;
    cursor: url('https://res.cloudinary.com/umarnazaket/image/upload/c_scale,w_25/v1660547167/eraser_cvutgk.png'), auto;
  }

  .highlight-annotation{
    background-color: #b1d1f1;;
    cursor: pointer;
  }
  .search-heighligh
  {
    cursor:default !important
  }

  .search-highlight {
    background-color: #ffac00;
  }

  .selected-highlight {
    background-color: #ff0;
  }